import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { ContainerWrapper } from '../components/styled/Container';
import { Feature } from '../../types';
import { UniversalLink } from '../components/UniversalLink';

interface Props {
  data: {
    features: {
      edges: Feature[];
    };
  };
}

const FeaturePageWrapper = styled(ContainerWrapper)``;
const FeatureWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;
const FeatureInner = styled.div`
  display: grid;
  grid-template-rows: 30px 1fr 50px 30px 30px;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  align-content: space-between;
  background-color: var(--surface);
  transition: background-color 500ms ease-in-out;
  padding: 1rem;
`;
const FeatureLabel = styled.h2`
  margin: 0;
  font-size: var(--font-size-l);
  font-weight: 600;
`;
const FeatureDesc = styled.p`
  margin: 0;
`;

const InspirationWrapper = styled.div`
  display: inline;
`;

const InspLabel = styled.span`
  font-weight: 500;
`;
const Inspirations = styled.ul`
  display: inline;
  grid-auto-flow: column;
  margin: 0;
  list-style-type: none;
  padding: 0;
`;
const Inspiration = styled.li`
  /* display: inline; */
`;
const StatusWrapper = styled.div<{ status: boolean }>`
  span {
    color: ${({ status }) =>
      status ? 'var(--text-success)' : 'var(--text-danger)'};
    text-decoration: ${({ status }) => (status ? 'line-through' : 'none')};
  }
`;

const SingleFeature = ({ node }: Feature) => {
  return (
    <FeatureInner>
      <FeatureLabel>{node.label}</FeatureLabel>
      <FeatureDesc>{node.description}</FeatureDesc>
      <InspirationWrapper>
        {node.inspiration && (
          <div>
            <InspLabel>Inspired by:</InspLabel>
            <Inspirations>
              {node.inspiration.map((ins) => (
                <Inspiration key={node.label + ins.url}>
                  <UniversalLink to={ins.url}>{ins.label}</UniversalLink>
                </Inspiration>
              ))}
            </Inspirations>
          </div>
        )}
      </InspirationWrapper>
      <StatusWrapper status={node.complete}>
        Status: <span>{node.complete ? 'Complete' : 'Not Complete'}</span>
      </StatusWrapper>
      <div>
        {node.blog && (
          <div>
            {/* <div>See how we did it:</div> */}
            <div>
              <UniversalLink
                to={node.blog}
              >{`Building ${node.label}`}</UniversalLink>
            </div>
          </div>
        )}
      </div>
    </FeatureInner>
  );
};

const todo = ({ data }: Props) => {
  const features = data.features.edges;
  return (
    <FeaturePageWrapper width='page' align='center'>
      <h1>Todo's</h1>
      <p>
        This is a list of things to add to this blog. Some things have alreayd
        been added.
      </p>
      <FeatureWrapper>
        {features.map((feature) => (
          <SingleFeature key={feature.node.label} node={feature.node} />
        ))}
      </FeatureWrapper>
    </FeaturePageWrapper>
  );
};

export default todo;

export const FeaturesQuery = graphql`
  query FeaturesQuery {
    features: allFeaturesJson(sort: { fields: complete }) {
      edges {
        node {
          label
          description
          complete
          blog
          inspiration {
            label
            url
          }
        }
      }
    }
  }
`;
